export default [
  {
    id: 'cx5-2012',
    model: 'MAZDA CX-5',
    year: 2012,
    engine: null,
    transmission: 'АКПП',
    drive: '4WD',
    price: 930000,
    photo: 'IMG_20190824_174424.jpg'
  },
  {
    id: 'ix35-2011',
    model: 'HYUNDAI IX35',
    year: 2011,
    engine: null,
    transmission: 'АКПП',
    drive: '4WD',
    price: 700000,
    photo: 'IMG_20190725_115019.jpg'
  },
  {
    id: 'asx-2013',
    model: 'MITSUBISHI ASX',
    year: 2013,
    engine: null,
    transmission: 'АКПП',
    drive: '4WD',
    price: 800000,
    photo: 'IMG_20200625_150346.jpg'
  },
  {
    id: 'zafira-2012',
    model: 'OPEL ZAFIRA',
    year: 2012,
    engine: null,
    transmission: 'АКПП',
    drive: null,
    price: 470000,
    photo: 'IMG_20190311_130351.jpg'
  },
  {
    id: 'gle-2017',
    model: 'MERCEDES GLE',
    year: 2017,
    engine: 'дизель',
    transmission: null,
    drive: null,
    price: 2800000,
    photo: 'IMG_20200727_134331.jpg'
  },
  {
    id: 'creta-2016',
    model: 'HYUNDAI CRETA',
    year: 2016,
    engine: '2.0',
    transmission: null,
    drive: '2WD',
    price: 870000,
    photo: 'IMG_20190313_151413.jpg'
  },
  {
    id: 'mondeo-2016',
    model: 'FORD MONDEO',
    year: 2016,
    engine: null,
    transmission: null,
    drive: null,
    price: 880000,
    photo: 'IMG_20190219_110538.jpg'
  },
  {
    id: 'murano-2014',
    model: 'NISSAN MURANO',
    year: 2014,
    engine: null,
    transmission: null,
    drive: null,
    price: 1050000,
    photo: 'IMG_20190524_135651.jpg'
  },
  {
    id: 'note-2012',
    model: 'NISSAN NOTE',
    year: 2012,
    engine: null,
    transmission: 'АКПП',
    drive: null,
    price: 450000,
    photo: 'IMG_20190409_164448.jpg'
  },
  {
    id: 'orlando-2014',
    model: 'CHEVROLET ORLANDO',
    year: 2014,
    engine: null,
    transmission: 'АКПП',
    drive: null,
    price: 700000,
    photo: 'IMG_20190314_172626.jpg'
  },
  {
    id: 'land-cruiser-prado-2009',
    model: 'TOYOTA LAND CRUISER PRADO',
    year: 2009,
    engine: 'дизель',
    transmission: null,
    drive: null,
    price: 1300000,
    photo: 'IMG_20201014_131655.jpg'
  },
  {
    id: 'rapid-2016',
    model: 'SKODA RAPID',
    year: 2016,
    engine: null,
    transmission: 'АКПП',
    drive: null,
    price: 600000,
    photo: 'IMG_20190813_162031.jpg'
  },
  {
    id: 'sandero-stepway-2019',
    model: 'RENAULT SANDERO STEPWAY',
    year: 2019,
    engine: null,
    transmission: 'МКПП',
    drive: null,
    price: 650000,
    photo: 'IMG_20200713_193737.jpg'
  },
  {
    id: 'santa-fe-2010',
    model: 'HYUNDAI SANTA FE',
    year: 2010,
    engine: 'дизель',
    transmission: null,
    drive: null,
    price: 830000,
    photo: 'IMG_20200122_125804.jpg'
  },
  {
    id: 'civic-2010',
    model: 'HONDA CIVIC',
    year: 2010,
    engine: null,
    transmission: 'АКПП',
    drive: null,
    price: 550000,
    photo: 'IMG_20201117_135015.jpg'
  },
  {
    id: 'soul-2016',
    model: 'KIA SOUL',
    year: 2016,
    engine: null,
    transmission: 'АКПП',
    drive: null,
    price: 750000,
    photo: 'IMG_20191004_112216.jpg'
  },
  {
    id: 'tiguan-2011',
    model: 'VOLKSWAGEN TIGUAN',
    year: 2011,
    engine: null,
    transmission: null,
    drive: '4WD',
    price: 650000,
    photo: 'IMG_20190917_112004.jpg'
  },
  {
    id: 'tuareg-2012',
    model: 'VOLKSWAGEN TUAREG',
    year: 2012,
    engine: null,
    transmission: null,
    drive: null,
    price: 1150000,
    photo: 'IMG_20191112_093758.jpg'
  },
  {
    id: 'freelander-2010',
    model: 'LAND ROVER FREELANDER',
    year: 2010,
    engine: 'дизель',
    transmission: null,
    drive: null,
    price: 740000,
    photo: 'IMG_20200808_184821.jpg'
  }
]
