import BoughtCars from '../components/BoughtCars'
import { Helmet } from 'react-helmet'
import Layout from '../components/MainLayout'
import React from 'react'

export default function BoughtCarsPage() {
  const title = 'Автомобили, которые мы купили'

  return (
    <Layout siteHeader={false} pageTitle={title}>
      <Helmet>
        <title>BuyMyCar - {title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <div className="container"></div>
      <BoughtCars />
    </Layout>
  )
}
