import { graphql, useStaticQuery } from 'gatsby'

// import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import React from 'react'
import boughtCars from '../data/bought-cars-data'
import styles from './BoughtCars.module.css'

export default function BoughtCars() {
  const data = useStaticQuery(graphql`
    query BoughtImagesQuery {
      allFile(filter: {sourceInstanceName: {eq: "bought-cars-img"}}) {
        nodes {
          base
          s: childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
          l: childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const imgData = {}
  data.allFile.nodes.forEach(entry => (imgData[entry.base] = entry))

  const [carToShow, setCarToShow] = React.useState(null)

  return (
    <div className="container">
      <div className="row">
        {boughtCars.map(car => (
          <div key={car.id} className="col-6">
            <Car {...car} img={imgData[car.photo]} onCarClick={() => setCarToShow(car)} />
          </div>
        ))}
      </div>
      <CarModal car={carToShow} imgData={imgData} onHide={() => setCarToShow(null)} />
    </div>
  )
}

function Car({ id, model, year, price, photo, transmission, engine, drive, onCarClick, img }) {
  return (
    <div className={styles.car}>
      <img width="250" src={img.s.fluid.src} alt={model} onClick={onCarClick} />
      <table>
        <tbody>
          <tr>
            <th colSpan="2">{model}</th>
          </tr>
          <tr>
            <td>Год</td>
            <td>{year}</td>
          </tr>
          {engine && (
            <tr>
              <td>Двигатель</td>
              <td>{engine}</td>
            </tr>
          )}
          {transmission && (
            <tr>
              <td>Коробка передач</td>
              <td>{transmission}</td>
            </tr>
          )}
          {drive && (
            <tr>
              <td>Привод</td>
              <td>{drive}</td>
            </tr>
          )}
          <tr>
            <td>Цена</td>
            <td>
              <span className={styles.price}>{formatPrice(price)}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

function CarModal({ car, onHide, imgData }) {
  return (
    <Modal show={car !== null} onHide={onHide} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        {car && (
          <Modal.Title id="contained-modal-title-vcenter">
            <span>{car.model}</span> <span>{car.year}</span> <span className={styles.price}>{formatPrice(car.price)}</span>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>{car && <img width="100%" src={imgData[car.photo].l.fluid.src} alt={car.model} onClick={onHide} />}</Modal.Body>
      {/*
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
*/}
    </Modal>
  )
}

function formatPrice(price) {
  return price.toLocaleString('ru-RU') + ' р'
}
